function validEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
}
  
function validateHuman(elements) {
    var honeypotFilledAmount = 0;

    for (var index = 0; index < elements.length; index++) {
        if(elements[index].name === "honeypot" && elements[index].value !== ""){
            honeypotFilledAmount++;
        }  
    }

    return honeypotFilledAmount > 0; //if honeypotFilledAmount > 0 then one of hidden fields has been filled up
}

function getFormFileds(elements) {
    return Object.keys(elements).filter(function(k) {
        // the filtering logic is simple, only keep fields that are not the honeypot
        return (elements[k].name !== "honeypot");
        }).map(function(k) {
            if(elements[k].name !== undefined) {
                return elements[k].name;
            // special case for Edge's html collection
            } else if(elements[k].length > 0) {
                return elements[k].item(0).name;
            }
        }).filter(function(item, pos, self) {
            return self.indexOf(item) == pos && item;
        });
}

function getDataFromFileds(elements, fields) {
    var data = {};
    fields.forEach(function(k) {
        data[k] = elements[k].value;
        var str = ""; // declare empty string outside of loop to allow
                    // it to be appended to for each item in the loop
        if (elements[k].type === "checkbox") { // special case for Edge's html collection
            str = str + elements[k].checked + ", "; // take the string and append 
                                                    // the current checked value to 
                                                    // the end of it, along with 
                                                    // a comma and a space
            data[k] = str.slice(0, -2); // remove the last comma and space 
                                        // from the  string to make the output 
                                        // prettier in the spreadsheet
        } else if(elements[k].length) {
            for(var i = 0; i < elements[k].length; i++) {
                if(elements[k].item(i).checked) {
                    str = str + elements[k].item(i).value + ", "; // same as above
                    data[k] = str.slice(0, -2);
                }   
            }
        }
    });

    return data;
}

// get all data in form and return object
function getFormData(form) {
    var elements = form.elements; // all form elements
    var fields = getFormFileds(elements);
    var data = getDataFromFileds(elements, fields);

    // add form-specific values into the data
    data.formDataNameOrder = JSON.stringify(fields);
    data.formGoogleSheetName = "dataRep"; // sheet name

    console.log(data);
    return data;
}

// handles form submit without any jquery
function submitContactForm(event, url, loadingMsg, sucessMsg, errorMsg) {   
    event.preventDefault();                                                 // we are submitting via xhr below
    
    var form = event.target;
    var data = getFormData(form);                                   // get the values submitted in the form

    // Enable SPAM prevention
    if (validateHuman(form.elements)) {                             // if one of the hidden fields is filled, form will not be submitted
        errorMsg("We're sorry, but you are not a human.");
        return false;
    }
    
    if (data.email && !validEmail(data.email)) {                            // if email is not valid show error
        errorMsg("We're sorry, but your email isn't valid.");
        return false;
    } 

    loadingMsg();  

    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        console.log( xhr.status, xhr.statusText )
        console.log(xhr.responseText);
        
        form.reset();
        sucessMsg();
        return;
    }
    xhr.onerror = function(e) {
        console.log("Error " + e.target.status + " occurred while receiving the document.");
        errorMsg("We're sorry, but the message didn't send, please try again later.");
    }

    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    
    console.log(encoded);
    
    xhr.send(encoded);
}

export default submitContactForm;