import $ from '@vladocar/nanojs/src/nanoJS';
import submitContactForm from './sendContact';

var SEND_CONTACT_FORM_DATA_URL = 'https://script.google.com/a/softdev4you.com/macros/s/AKfycbyMvG7DrpVZcbJxMA5vkpPTQysODeaz0SR7ECD9TA/exec';

function loadingMsg() {
  $('.alert-loading').toggleClass('alert-show');
  $('#contactForm button').attr('disabled', 'true');
}

function sucessMsg() {
  $('.alert-loading').toggleClass('alert-show');
  $('.alert-success').addClass('alert-show');
  $('#contactForm .form-control').each((i) => { i.value = ''; });
  $('#contactForm button').removeAttr('disabled');
}

function errorMsg (message) {
  $('.alert-loading').removeClass('alert-show');
  $('.alert-error').addClass('alert-show').html(message);
  $('#contactForm button').removeAttr('disabled');
}

function addActionToContactForm() {
  $('#contactForm').on('submit', (event) => {
    $('.alert-success').removeClass('alert-show');
    $('.alert-error').removeClass('alert-show');
   
    submitContactForm(event, SEND_CONTACT_FORM_DATA_URL, loadingMsg, sucessMsg, errorMsg);
  });
};


// function smoothJumpUp () {
//   if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {  
//     window.scroll({
//       top: 0,
//       behavior: "smooth"
//     });
//   }
// }

// function smoothJumpDown () {
//   var height = document.body.scrollHeight || document.documentElement.scrollHeight || document.body.clientHeight;
//   if (document.body.scrollTop < height || document.documentElement.scrollTop < height) { 
//     window.scroll({
//       top: height,
//       behavior: "smooth"
//     });
//   }
// }

// function addActionToScrollButton() {

//    var btnScroll = $('.btn-scroll');

//   btnScroll.on('click', () => {
//     var scrolled = window.pageYOffset || document.documentElement.scrollTop;
//     if (scrolled > 100) {
//       smoothJumpUp();
//     } else {
//       smoothJumpDown();
//     }
//   });

//   window.onscroll = function() {
//     var scrolled = window.pageYOffset || document.documentElement.scrollTop;
//     if (scrolled >= 100) {
//       btnScroll.addClass('arrow-top').removeClass('arrow-bottom');
//     } else {
//       btnScroll.addClass('arrow-bottom').removeClass('arrow-top');
//     }
//   }
// }

function addActionToShowBg() {
  var bg = $('#sub-menu-bg'); 

  $('.nav-top > ul > li')
    .on('mouseover', () => { 
      bg.css('display: block;'); 
    })
    .on('mouseout', () => {
      bg.css('display: none;');
    });
}

function addActionToOpenMenu() {
  var btn_menu = $('.btn-menu');
  
  btn_menu.on('click', () => {
    btn_menu.toggleClass('btn-menu-close'); 
    $('#topMenu').toggleClass('nav-top-open');
  });
}


document.addEventListener('DOMContentLoaded', () => {
  // addActionToScrollButton();
  addActionToShowBg();
  addActionToOpenMenu();

  addActionToContactForm();
});
